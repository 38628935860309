import { useMiddleware } from "@/store/middleware";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const auth = useAuth();
  const config = useRuntimeConfig();
  const middleware = useMiddleware();

  const host = window.location.host;
  const subdomain = host.split(".")[0];

  if (subdomain !== "app") {
    return navigateTo("/hub/sessions");
  } else {
    return;
  }
});
